import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAPIParams, IAPIPayload, IDataMetadataResponse } from "lib/APIUtils";
import {  bearer } from 'lib/AuthUtils';

export interface ICreateEntryFromAdminThunkParams {
  organization_id: string,
  collection_id: string
  jwt: string,
  body: any
}

export const createEntryFromAdmin = createAsyncThunk(
  'POST api/admin/entries/organization_id/collection_id',
  async (thunkParams:ICreateEntryFromAdminThunkParams) => {
    const {organization_id, collection_id, jwt, body } = thunkParams;
    const key = `/api/admin/entries/${organization_id}/${collection_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'POST',
      headers: {
        ...bearer(jwt),
        ...{
          'Content-Type':'application/json'
        }
      },
      body: JSON.stringify(body)
    });
    const resp = await respRaw.json();
    const payload:IDataMetadataResponse = {
      data: resp.data,
      metadata: resp.metadata ?? {}
    }
    return payload;
  }
);
