import { createAsyncThunk } from '@reduxjs/toolkit';
import Collection from 'backend/models/Collection';
import { DataMetadataResponse } from 'lib/APIUtils';
import {  bearer, contentTypeJson } from 'lib/AuthUtils';
import { slsFetch } from 'lib/Client';

export type IFetchCollectionsParams = {
  organization_id: string,
  jwt: string
}

//deprecate, use CollectionParams instead
type CollectionsBodyParams = {
  body: Collection
}

type CollectionParams = {
  collection: Collection
}

type SingleCollectionParam = {
  collection_id: string
}

export const fetchCollections = createAsyncThunk(
  'collections/fetch',
  async ({organization_id, jwt}:IFetchCollectionsParams) => {
    const responseRaw:any = await slsFetch(`/api/admin/collections/${organization_id}/`,{
      headers: {
        ...bearer(jwt)
      }
    });
    const response:any = await responseRaw.json();
    return {
      organization_id,
      data: response.data
    }
  },
);

export const createCollection =  createAsyncThunk(
  'POST collections',
  async ({organization_id, jwt, body}:IFetchCollectionsParams & CollectionsBodyParams) => {
    const responseRaw:any = await slsFetch(`/api/admin/collections/${organization_id}/`,{
      method: 'POST',
      headers: {
        ...bearer(jwt),
        ...contentTypeJson()
      },
      body: JSON.stringify(body)
    });
    const response:DataMetadataResponse<Collection> = await responseRaw.json();
    const output:DataMetadataResponse<Collection> = {
      data: response.data,
      metadata: {
        organization_id
      }
    }

    return output;

  }
);

export const deleteCollection = createAsyncThunk(
  'DELETE collections',
  async ({organization_id, jwt, collection_id}:IFetchCollectionsParams & SingleCollectionParam) => {
    const responseRaw:any = await slsFetch(`/api/admin/collections/${organization_id}/${collection_id}/`,{
      method: 'DELETE',
      headers: {
        ...bearer(jwt)
      }
    });
    await responseRaw.json();
    const output:DataMetadataResponse<Collection> = {
      metadata: {
        organization_id,
        collection_id
      }
    }

    return output;
  }
);

export const updateCollection =  createAsyncThunk(
  'PUT collections',
  async ({organization_id, jwt, collection}:IFetchCollectionsParams & CollectionParams) => {
    const responseRaw:any = await slsFetch(`/api/admin/collections/${organization_id}/${collection.id}`,{
      method: 'PUT',
      headers: {
        ...bearer(jwt),
        ...contentTypeJson()
      },
      body: JSON.stringify(collection)
    });
    const response:DataMetadataResponse<Collection> = await responseRaw.json();
    const output:DataMetadataResponse<Collection> = {
      data: response.data,
      metadata: {
        organization_id
      }
    }

    return output;

  }
);
