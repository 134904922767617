import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Form1Values } from 'components/signup/Form1';
import { Form2Values } from 'components/signup/Form2';
import { Form3Values } from 'components/signup/Form3';
import { signup } from './thunks';

export interface ISignupSlice {
  organizationName: string,
  organizationId: string,
  firstname: string,
  lastname: string,
  email: string,
  isFooterLoading: boolean,
  purpose: Record<string, boolean>
}

const initialState:ISignupSlice = {
  organizationName: '',
  organizationId: '',
  firstname: '',
  lastname: '',
  email: '',
  purpose: {
    blog: false,
    page: false,
    gallery: false,
    portfolio: false,
    product_catalog: false
  },
  isFooterLoading: false
}

export const signupSlice = createSlice({
  name: 'signupSlice',
  initialState,
  reducers: {

    setForm1: (state, action: PayloadAction<Form1Values>) => {
      state.organizationName = action.payload.organizationName;
      state.organizationId = action.payload.organizationId;
    },

    setForm2: (state, action: PayloadAction<Form2Values>) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
    },

    setForm3: (state, action: PayloadAction<Form3Values>) => {
      state.purpose = action.payload.purpose;
    },

    setFooterLoading: (state, action: PayloadAction<boolean>) => {
      state.isFooterLoading = action.payload;
    }

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    
    builder.addCase(signup.fulfilled, (state, action) => {
      // Add user to the state array
      //alert(action.payload);
      /*
      const {organization_id, data} = action.payload;
      state.data = {
        ...state.data,
        [organization_id]: data
      }*/
    })
    
  },
})

// Action creators are generated for each case reducer function
export const { setForm1, setForm2, setForm3, setFooterLoading } = signupSlice.actions

export default signupSlice.reducer;

export * from './selectors';
export * from './thunks';