import { createAsyncThunk } from '@reduxjs/toolkit';
import { bearer } from 'lib/AuthUtils';
import { IResponseAdminMe } from 'pages/api/admin/me';
import { slsFetch } from "lib/Client";

export const fetchUserOrganizationIds = createAsyncThunk(
  'users/organizationIds',
  async (jwt: string, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const respRaw: Partial<Response> = await slsFetch('/api/admin/me/', {
      headers: {
        ...bearer(jwt)
      }
    });

    const resp = (await respRaw.json()) as IResponseAdminMe;
    return resp;
  },
)
