import { createAsyncThunk } from '@reduxjs/toolkit';
import Attribute from 'backend/models/Attribute';
import Entry from 'backend/models/Entry';
import { IAPIParams, IAPIPayload, IAPIThunkPayload, IDataMetadataResponse, IEntryAPIParams } from 'lib/APIUtils';
import { bearer, contentTypeJson } from 'lib/AuthUtils';

export const fetchOrganizationCollection = createAsyncThunk(
  'GET /api/organization/collection',
  async ({ organization_id, collection_id }: IAPIParams) => {
    const key = `/api/${organization_id}/${collection_id}`;
    const respRaw = await fetch(`${key}/?metadata=1`);
    const resp = await respRaw.json();
    const payload: IAPIPayload = {
      key,
      data: resp.data,
      metadata: resp.metadata ?? {}
    }
    return payload;
  },
);

export const fetchEntry = createAsyncThunk(
  'GET /api/organization_id/collection_id/entry_id',
  async (apiParams: IEntryAPIParams) => {
    const { organization_id, collection_id, entry_id } = apiParams;
    const key = `/api/${organization_id}/${collection_id}/${entry_id}`;
    const respRaw = await fetch(`${key}/?metadata=1`);
    const resp = await respRaw.json();
    const payload: IAPIPayload = {
      key,
      data: resp.data,
      metadata: resp.metadata ?? {}
    }
    return payload;
  }
);

//Admin-related thunks

type OrganizationCollectionAdminParams = {
  organization_id: string,
  collection_id: string,
  jwt: string
}

type AttributeParams = {
  attribute: Attribute
}

type AttributeIdParams = {
  attribute_id: string
}

export interface ICreateEntryFromAdminThunkParams {
  organization_id: string,
  collection_id: string,
  jwt: string,
  body: any
}

export interface IDeleteEntryFromAdminThunkParams {
  organization_id: string,
  collection_id: string,
  entry_id: string,
  jwt: string
}

export interface IUpdateEntryFromAdminThunkParams {
  organization_id: string,
  collection_id: string,
  entry_id: string,
  entry: any,
  jwt: string
}

export const createEntryFromAdmin = createAsyncThunk(
  'POST /api/admin/entries/organization_id/collection_id',
  async (thunkParams: ICreateEntryFromAdminThunkParams) => {
    const { organization_id, collection_id, jwt, body } = thunkParams;
    const key = `/api/admin/entries/${organization_id}/${collection_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'POST',
      headers: {
        ...bearer(jwt),
        ...{
          'Content-Type': 'application/json'
        }
      },
      body: JSON.stringify(body)
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<Entry> = {
      keys: [organization_id, collection_id],
      data: resp.data,
      metadata: resp.metadata ?? {}
    }
    return payload;
  }
);

export const deleteEntryFromAdmin = createAsyncThunk(
  'DELETE /api/admin/entries/organization_id/collection_id',
  async (thunkParams: IDeleteEntryFromAdminThunkParams) => {
    const { organization_id, collection_id, entry_id, jwt } = thunkParams;
    const key = `/api/admin/entries/${organization_id}/${collection_id}/${entry_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'DELETE',
      headers: {
        ...bearer(jwt)
      }
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<any> = {
      keys: [organization_id, collection_id, entry_id],
    }
    return payload;
  }
);

export const updateEntryFromAdmin = createAsyncThunk(
  'PUT /api/admin/entries/organization_id/collection_id',
  async (thunkParams: IUpdateEntryFromAdminThunkParams) => {
    const { organization_id, collection_id, entry_id, entry, jwt } = thunkParams;
    const key = `/api/admin/entries/${organization_id}/${collection_id}/${entry_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'PUT',
      headers: {
        ...bearer(jwt),
        ...{
          'Content-Type': 'application/json'
        }
      },
      body: JSON.stringify(entry)
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<any> = {
      keys: [organization_id, collection_id, entry_id],
      data: resp.data,
      metadata: resp.metadata
    }
    return payload;
  }
);

export const createAttributeFromAdmin = createAsyncThunk(
  'POST /api/admin/attributes/organization_id/collection_id',
  async (thunkParams: OrganizationCollectionAdminParams & AttributeParams) => {
    const { organization_id, collection_id, jwt, attribute } = thunkParams;
    const key = `/api/admin/attributes/${organization_id}/${collection_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'POST',
      headers: {
        ...bearer(jwt),
        ...contentTypeJson()
      },
      body: JSON.stringify(attribute)
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<Attribute> = {
      data: resp.data,
      metadata: resp.metadata
    }
    return payload;
  }
);

export const deleteAttributeFromAdmin = createAsyncThunk(
  'DELETE /api/admin/attributes/organization_id/collection_id/attribute_id',
  async (thunkParams: OrganizationCollectionAdminParams & AttributeIdParams) => {
    const { organization_id, collection_id, jwt, attribute_id } = thunkParams;
    const key = `/api/admin/attributes/${organization_id}/${collection_id}/${attribute_id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'DELETE',
      headers: {
        ...bearer(jwt)
      }
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<Attribute> = {
      metadata: resp.metadata
    }
    return payload;
  }
);


export const updateAttributeFromAdmin = createAsyncThunk(
  'PUT /api/admin/attributes/organization_id/collection_id/attribute_id',
  async (thunkParams: OrganizationCollectionAdminParams & AttributeParams) => {
    const { organization_id, collection_id, jwt, attribute } = thunkParams;
    const key = `/api/admin/attributes/${organization_id}/${collection_id}/${attribute.id}`;
    const respRaw = await fetch(`${key}/`, {
      method: 'PUT',
      headers: {
        ...bearer(jwt),
        ...contentTypeJson()
      },
      body: JSON.stringify(attribute)
    });
    const resp = await respRaw.json();
    const payload: IAPIThunkPayload<any> = {
      data: resp.data,
      metadata: resp.metadata
    }
    return payload;
  }
);



