import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchCollections } from './thunks';
import Collection from 'backend/models/Collection';


export type OrganizationIdData = {
  organization_id: string,
  data: Collection[]
}

const initialState = {
  data: {}
}

export const collectionSlice = createSlice({
  name: 'collectionSlice',
  initialState,
  reducers: {
    //increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //  state.value += 1
    //},
    //decrement: (state) => {
    //  state.value -= 1
    //},
    //incrementByAmount: (state, action: PayloadAction<number>) => {
    //  state.value += action.payload
    //},
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCollections.fulfilled, (state, action:PayloadAction<OrganizationIdData>) => {
      // Add user to the state array
      //alert("here");
      const {organization_id, data} = action.payload;
      state.data = {
        ...state.data,
        [organization_id]: data
      }
    })
  },
})

// Action creators are generated for each case reducer function
//export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default collectionSlice.reducer;

export * from './selectors';
export * from './thunks';