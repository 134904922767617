import { configureStore } from '@reduxjs/toolkit';
import collectionReducer from 'features/collections/collectionSlice';
import meReducer from 'features/me/meSlice';
import apiReducer from 'features/api/apiSlice';
import adminReducer from 'features/admin/adminSlice';
import adminUIReducer from 'features/adminUI/adminUISlice';
import signupReducer from 'features/signup/signupSlice';

import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from "redux"; 

const reducers = combineReducers({
  collections: collectionReducer,
  me: meReducer,
  api: apiReducer,
  admin: adminReducer,
  adminUI: adminUIReducer,
  signup: signupReducer         
 });

const persistConfig = {
  key: 'root',
  storage
};
 
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch