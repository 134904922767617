import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoIdToken } from '@xeedware/cognito-jwt';
import { useState, useEffect } from "react";
import { NextApiRequest } from 'next';
import { CognitoJwtVerifier } from "aws-jwt-verify";
// @ts-ignore
import InsufficientPermissionsError from '@klaudsol/commons/errors/InsufficientPermissionsError';



export const currentJWT = async ():Promise<string> => {
  const user:CognitoUser = await Auth.currentAuthenticatedUser();
  return user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
}
 
export const currentIdToken = async ():Promise<CognitoIdToken> => {
  const jwt = await currentJWT();
  return new CognitoIdToken(jwt);
}

export const useCurrentIdToken = ():CognitoIdToken => {
  const [idToken, setIdToken] = useState<CognitoIdToken>();
  
  useEffect(() => {

    (async () => {
      try {
        const _idToken:CognitoIdToken = await currentIdToken();
        setIdToken(_idToken);
      } catch(error) {
        console.error(error);
      }

    })();

  }, []);  

  return idToken;
}

export const useCurrentJWT = ():string => {
  const [jwt, setJwt] = useState<string>();
  
  useEffect(() => {

    (async () => {
      try {
        const _jwt:string = await currentJWT();
        setJwt(_jwt);
      } catch (error) {
        console.error(error);
      }

    })();

  }, []);  

  return jwt;
}

export const extractTokenFromRequest = (req:NextApiRequest):string => {
  if(!req?.headers?.authorization) throw new InsufficientPermissionsError("Bearer token is required.");
  return req.headers.authorization.substring("Bearer ".length);
}

export const verifyAndDecode = async (jwt:string):Promise<CognitoIdToken> => {

      //TODO: Cache verification as not to contact Cognito repeatedly
      //and overwhelm it.

      //Verify token via contacting AWS Cognito
      const verifier = CognitoJwtVerifier.create({
        userPoolId: process.env.KS_AWS_COGNITO_POOL_ID,
        tokenUse: "id",
        clientId: process.env.KS_AWS_COGNITO_APP_CLIENT_ID,
      });

      try {
        await verifier.verify(jwt);
        return new CognitoIdToken(jwt);
      } catch(error) {
        console.error(error);
        throw new InsufficientPermissionsError("JWT failed Cognito verification.");
      }
}

export interface IAuthorizationHeader {
  'Authorization': string
}

export const bearer = (jwt: string):IAuthorizationHeader => {
  return  {
    'Authorization': `Bearer ${jwt}`
  };
}

export const contentTypeJson = () => {
  return {
    'Content-Type':'application/json'
  }
}

export enum Capability {
  MemberOfOrganization = 'MemberOfOrganization'
}

export const assertUser = async(userId: string,  capability: Capability, ...params) => {
  //for now
  return true;
}
