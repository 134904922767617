import React, { useReducer } from "react";
import Head from "next/head";
import { Amplify } from 'aws-amplify';
import { store } from 'components/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import "styles/coreui/style.scss";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/globals.scss";
import "styles/klaudsolcms.scss";
import "styles/general.scss";

import { useEffect } from "react";
import { useRouter } from "next/router";
import * as gtag from "lib/gtag";
import { Poppins } from '@next/font/google';


const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
})

//Salvaged from aws-exports.js
const amplifyConfig = {
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_28b0RCyZZ",
  "aws_user_pools_web_client_id": "7akdja3lne6ttm1dbos8dnrp7s",
  "oauth": {
    "domain": "cms5.auth.us-east-1.amazoncognito.com",
    "scope": [
      "email",
      "openid",
      "phone"
    ],
    "redirectSignIn": `${process.env.NEXT_PUBLIC_URL ?? "http://localhost:3000"}/oauth/`,
    "redirectSignOut": `${process.env.NEXT_PUBLIC_URL ?? "http://localhost:3000"}/`,
    "responseType": "code"
  }
};




Amplify.configure(amplifyConfig);


const theme = extendTheme({
  colors:{
    buttonBg: {
      primary: '#455a64'
    },
    button: {
      primary: 'white'
    },
    app: {
      primary: '#455a64',
      secondary: '#eceff1',
      tertiary: 'lightgrey',
      danger: 'maroon',
      text: {
        light: 'white',
        dark: 'black'
      }
    }
  },
  styles: {
    global: {
      body: {
        ...poppins.style,
        fontWeight: 'normal'
      }
    }
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'normal'
      },
      sizes: {
        md: {
          fontWeight: 'normal',
          fontSize: '12px'        
        }
      }
    },
    Table: {
      variants: {
        striped: {
          td: {
            _odd: {
              background: "app.secondary"
            }
          }          
        }
      }
    },
    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: 'unset',
            width: '800px'
          }
        }
      }
    },
    Heading: {
      baseStyle: {
        ...poppins.style,
        fontWeight: 'normal',
        lineHeight: 1
      }
    },
    Tabs: {
      variants: {
        unstyled: {
          fontWeight: 'normal',
          fontSize: '12px', 
          paddingY: '4',
          marging: '0',
          tab: {
            _selected: {
              color: 'white',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Code: {
      baseStyle: {
        fontWeight: 'normal',
        fontSize: '12px', 
      } 
    }
  }
});

export default function MyApp({ Component, pageProps }): JSX.Element {
  const router = useRouter();
  const persistor = persistStore(store);



  return (
    <React.StrictMode>

      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>

            {/*Important! PersistGate must have only one child: https://github.com/vercel/next.js/issues/8240*/}
            {() => (
              <>
                <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <title>
                    KlaudSol CMS
                  </title>
                </Head>

                <div className={poppins.className}>
                  <Component {...pageProps} />
                </div>
              </>

            )}


          </PersistGate>
        </ChakraProvider>
      </Provider>

    </React.StrictMode>
  );
}
