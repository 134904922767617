import { ErrorType } from "lib/Utils";

export default class RecordNotFound extends Error implements ErrorType {
  
  static error_name = 'RecordNotFound';

  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    Object.setPrototypeOf(this, RecordNotFound.prototype);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RecordNotFound);
    }

    this.name = RecordNotFound.error_name;
  }

}

