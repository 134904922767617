import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'components/store';

export const collectionDataSelector = (organization_id:string, collection_id:string) => 
  createSelector(
    [(state:RootState) => state.api?.api?.[`/api/${organization_id}/${collection_id}`]?.data],
     data => data ?? [],
    {devModeChecks: {identityFunctionCheck: 'never'}}
  );

  export const collectionMetadataSelector = (organization_id:string, collection_id:string) => 
    createSelector(
      [(state:RootState) => state.api?.api?.[`/api/${organization_id}/${collection_id}`]?.metadata],
      metadata => metadata,
      {devModeChecks: {identityFunctionCheck: 'never'}}
    );  

export const attributesSelector = (organization_id:string, collection_id:string) => 
  createSelector(
    [(state:RootState) => state.api?.api?.[`/api/${organization_id}/${collection_id}`]?.metadata?.attributes],
    attributes => Object.fromEntries(Object.entries(attributes ?? {}).sort((a:any, b:any) => Number(a[1].order ?? Math.max()) - Number(b[1].order ?? Math.max()))),
    {devModeChecks: {identityFunctionCheck: 'never'}}
  );

export const attributesForEntriesSelector = (organization_id:string, collection_id:string, entry_id:string) => 
  createSelector(
    [(state:RootState) => state.api?.api?.[`/api/${organization_id}/${collection_id}/${entry_id}`]?.metadata?.attributes],
    (attributes) => attributes ?? {},
    {devModeChecks: {identityFunctionCheck: 'never'}}
  );

export const entryDataSelector = (organization_id:string, collection_id:string, entry_id:string) => 
  createSelector(
    [(state:RootState) => state.api?.api?.[`/api/${organization_id}/${collection_id}/${entry_id}`]?.data],
    (attributes) => attributes ?? {},
    {devModeChecks: {identityFunctionCheck: 'never'}}
  );


export const presignedUrlsSelector = ({api}:RootState) =>  api.presignedUrls;

  