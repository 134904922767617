import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IAdminUISlice {
  isSaving: boolean,
  isDeleting: boolean,
  flashMessage?: string
}

const initialState:IAdminUISlice = {
  isSaving: false,
  isDeleting: false
}

export const adminUISlice = createSlice({
  name: 'adminUISlice',
  initialState,
  reducers: {
    //increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //  state.value += 1
    //},
    //decrement: (state) => {
    //  state.value -= 1
    //},
    setSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
    setDeleting: (state, action: PayloadAction<boolean>) => {
      state.isDeleting = action.payload;
    },
    setFlashMessage: (state, action: PayloadAction<string>) => {
      state.flashMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    /*
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      // Add user to the state array
      //alert("here");
      const {organization_id, data} = action.payload;
      state.data = {
        ...state.data,
        [organization_id]: data
      }
    })
    */
  },
})

// Action creators are generated for each case reducer function
export const { setSaving, setFlashMessage, setDeleting } = adminUISlice.actions

export default adminUISlice.reducer;

export * from './selectors';
export * from './thunks';