import { createAsyncThunk } from "@reduxjs/toolkit";
import { bearer } from "lib/AuthUtils";
import { slsFetch } from "lib/Client";
import { ISignupSlice } from "./signupSlice";

type SignupParams = {
  signupData: ISignupSlice
}

type JwtParams = {
  jwt: string
}

type OrganizationIdParams = {
  organization_id: string
}

export const signup = createAsyncThunk(
  'admin/signup',
  async ({ jwt, signupData }: JwtParams & SignupParams, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const respRaw: Partial<Response> = await slsFetch('/api/admin/signup/', {
      method: 'POST',
      headers: {
        ...bearer(jwt),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(signupData)
    });

    const resp = (await respRaw.json());
    return resp;
  },
)

export const checkOrganizationId = createAsyncThunk(
  'GET /api/admin/checkOrganizationId/:organization_id',
  async ({ jwt, organization_id }: JwtParams & OrganizationIdParams, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {

      const respRaw: Partial<Response> = await slsFetch(`/api/admin/check_organization_id/${organization_id}`, {
        method: 'GET',
        headers: {
          ...bearer(jwt)
        }
      });

      const resp = (await respRaw.json());
      return resp;

  },
)