import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntryFromAdmin, deleteEntryFromAdmin, fetchEntry, fetchOrganizationCollection, updateEntryFromAdmin } from './thunks';
import { IAPIPayload, IAPIThunkPayload, IDataMetadataResponse } from 'lib/APIUtils';
import Entry from 'backend/models/Entry';

interface IAPISlice {
  api: Record<string, IDataMetadataResponse>,
  presignedUrls: Record<string, string>
}


const initialState:any = {
  api: {},
  presignedUrls: {}
};

export const apiSlice = createSlice({
  name: 'apiSlice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    
    builder.addCase(fetchOrganizationCollection.fulfilled, (state, action: PayloadAction<IAPIPayload>) => {
      const {key, data, metadata}= action.payload;
      state.api[key] = {
        data,
        metadata
      }
    });

    builder.addCase(fetchEntry.fulfilled, (state, action: PayloadAction<IAPIPayload>) => {
      const {key, data, metadata}= action.payload;
      state.api[key] = {
        data,
        metadata
      }
    });
    
        
    builder.addCase(createEntryFromAdmin.fulfilled, (state, action: PayloadAction<IAPIThunkPayload<Entry>>) => {
      // Add user to the state array
      //alert("here");
      
      /* Much wiser to let the refresh do the hard work due to the ordering rules.
      console.error(action.payload);
      const { keys, data, metadata} = action.payload;
      const [organization_id, collection_id] = keys;

      //Add to entries
      state.api[`/api/${organization_id}/${collection_id}/${data.id}`] = {
        ...data
      };

      //Add to collections
      if (state.api[`/api/${organization_id}/${collection_id}`].data) {
        state.api[`/api/${organization_id}/${collection_id}`].data.concat(data);
      } else {
        state.api[`/api/${organization_id}/${collection_id}`].data = [data];
      }
      */
      
    });

    builder.addCase(deleteEntryFromAdmin.fulfilled, (state, action: PayloadAction<IAPIThunkPayload<Entry>>) => {

      const { keys } = action.payload;
      const  [organization_id, collection_id, entry_id] = keys;

      //delete entry
      delete state.api[`/api/${organization_id}/${collection_id}/${entry_id}`];

      //delete from collection
      const index = state.api[`/api/${organization_id}/${collection_id}`]?.data?.findIndex(entry => entry.id === entry_id);
      if(index != null) state.api[`/api/${organization_id}/${collection_id}`].data.splice(index, 1);

      
    });  

    builder.addCase(updateEntryFromAdmin.fulfilled, (state, action: PayloadAction<IAPIThunkPayload<Entry>>) => {

      const { keys, data, metadata } = action.payload;
      const  [organization_id, collection_id, entry_id] = keys;

      //update entry. 
      //This needs to be defered until the images are uploaded to S3 
      //state.api[`/api/${organization_id}/${collection_id}/${entry_id}`].data = data;

      //update collection
      const index = state.api[`/api/${organization_id}/${collection_id}`]?.data?.findIndex(entry => entry.id === entry_id);
      if(index != null) state.api[`/api/${organization_id}/${collection_id}`].data[index] = data;

      //update presigned urls for file upload
      if(metadata.presignedUrls) {
        state.presignedUrls = {
          ...state.presignedUrls,
          ...metadata.presignedUrls
        }
      };

      
    });      
    
  },
})

// Action creators are generated for each case reducer function
//export const { logout } = apiSlice.actions

export default apiSlice.reducer;

export * from './selectors';
export * from './thunks';