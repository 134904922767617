import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchUserOrganizationIds } from './thunks';
import { IResponseAdminMe } from 'pages/api/admin/me';
import User from 'backend/models/User';

type UserSliceType = Partial<User> & {
  currentOrganization?: string,
};

export interface IUserSlice extends UserSliceType {};

const initialState:IUserSlice = {
  organizationIds: []
}

export const meSlice = createSlice({
  name: 'meSlice',
  initialState,
  reducers: {
    logout: (state) => {
      //nuke current user data on logout
      state.organizationIds = initialState.organizationIds;
      state.currentOrganization = initialState.currentOrganization;
      state.id = undefined;
      state.firstname = undefined;
      state.lastname = undefined;
    },

  },
  extraReducers: (builder) => {
    
    builder.addCase(fetchUserOrganizationIds.fulfilled, (state, action: PayloadAction<IResponseAdminMe>) => {
      const payload:IResponseAdminMe = action.payload;
      state.organizationIds = payload.organizationIds;
      if(payload.organizationIds.length >  0) {
        state.currentOrganization = payload.organizationIds[0];
      }
      if(payload.id) state.id = payload.id;
      if(payload.firstname) state.firstname = payload.firstname;
      if(payload.lastname) state.lastname = payload.lastname;

    });
    
  },
})

// Action creators are generated for each case reducer function
export const { logout } = meSlice.actions

export default meSlice.reducer;

export * from './selectors';
export * from './thunks';