import { COMMUNICATION_LINKS_FAILURE, NOT_FOUND, UNAUTHORIZED } from '@klaudsol/commons/lib/HttpStatuses';

//Deprecate @klaudsol/commons. Copy to own lib/errors instead.
import UnauthorizedError from '@klaudsol/commons/errors/UnauthorizedError';
import AppNotEnabledError from '@klaudsol/commons/errors/AppNotEnabledError';
import InsufficientPermissionsError from '@klaudsol/commons/errors/InsufficientPermissionsError';
import SessionNotFound from '@klaudsol/commons/errors/SessionNotFound';
import MissingHeaderError from '@klaudsol/commons/errors/MissingHeaderError';
import InvalidTokenError from '@klaudsol/commons/errors/InvalidTokenError';
import TokenExpiredError from '@klaudsol/commons/errors/TokenExpiredError';
import JsonWebTokenError from '@klaudsol/commons/errors/JsonWebTokenError';
import InsufficientDataError from '@klaudsol/commons/errors/InsufficientDataError';
import UserAlreadyExists from '@klaudsol/commons/errors/UserAlreadyExists';

import RecordNotFound from 'lib/errors/RecordNotFound';


//Big idea: This allows backend exceptions to "pass-through" through the API through the frontend.

type ErrorResponseType = {
  name?: string,
  message?: string,
  status: number
}

const responseToError = (response:ErrorResponseType) => {
  const allowedErrors = [
    UnauthorizedError,
    AppNotEnabledError,
    InsufficientPermissionsError,
    SessionNotFound,
    MissingHeaderError,
    InvalidTokenError,
    TokenExpiredError,
    JsonWebTokenError,
    InsufficientDataError,
    UserAlreadyExists,
    RecordNotFound
  ];

  const currentError = allowedErrors.find(error => error.name === response.name);

  if(currentError) {
    return new currentError(response.message);
  } else {
    return new Error(`Response status: ${response.status}`);
  }
};

export const slsFetch = async (url, params, extra=null) => {
  const response = await fetch(url, params);
  const status = response.status;
  //const responseJson = await response.json();
  if (status >= 200 && status <= 299) {
    return response;
  } else if (status === NOT_FOUND) {
    throw new RecordNotFound();
  } else {
    const currentError = responseToError({status: response.status});
    throw currentError;
  }
};